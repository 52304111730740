<template>
  <div v-if="getRosetteVisibility">
    <div class="rosette-container">
      <div style="margin-top:20px">
        <VueText sizeLevel="10" weightLevel="3">Aktif+ Görevlerim</VueText>
        <div class="rosette-box">
          <div
            v-for="(rosette, index) in getRosettesInfo"
            :key="index"
            class="rosette-item"
            id="rosetteItem"
          >
            <div @click="selectCurrentRosette(rosette)" class="circular-progress">
              <div class="progress-bar" :id="`progress-bar-${index}`">
                <img :src="`${rosette.rosetteImageUrl}`" alt="rosette" class="rosette-image" />
              </div>
            </div>
            <VueText class="rosette-name" weightLevel="2">{{ rosette.rosetteName }}</VueText>
            <DynamicRosetteButton
              @openNpsSurveyPopup="openNpsSurveyPopup"
              style="margin: 20px 0;"
              :rosette="rosette"
              :hasActiveMarathons="hasActiveMarathons"
              :hasNpsSurvey="hasNpsSurvey"
              class="dynamic-rosette-button"
            />
          </div>
        </div>
      </div>
      <BrandButton
        @click="redirectToRosetteDetail"
        :radius="50"
        shape="rounded"
        class="all-show-button"
      >
        <VueText :isSingleLine="true" color="white-100" sizeLevel="5" weightLevel="3">
          Tümünü Gör</VueText
        >
      </BrandButton>
      <BrandInfoPopup
        style="backdrop-filter: blur(1px) !important;"
        :show.sync="showRosetteModal"
        @update:show="closeRosetteModal"
      >
        <div>
          <RosettePopupContent :selectedRosette="selectedRosette" />
          <DynamicRosetteButton
            @openNpsSurveyPopup="openNpsSurveyPopup"
            :rosette="selectedRosette"
            :hasActiveMarathons="hasActiveMarathons"
            :hasNpsSurvey="hasNpsSurvey"
          />
        </div>
      </BrandInfoPopup>
    </div>
    <NpsSurvey
      v-if="isNpsRosette"
      :npsSurveyId="npsSurveyId"
      :isRosetteNps="true"
      @closeModal="closeRosetteModal"
    />
  </div>
</template>

<script>
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import RosettePopupContent from './RosettePopupContent.vue';
import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';
import RosetteInfo from '@/mixins/rosetteInfo.js';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import DynamicRosetteButton from '@/modules/rosettes/components/DynamicRosetteButton.vue';
import GameApi from '@/services/Api/gamification';
import StorageHelper from '@/utils/storageHelper';
import { Common } from '@/services/Api/index';

export default {
  name: 'Rosettes',
  components: {
    VueText,
    BrandButton,
    RosettePopupContent,
    BrandInfoPopup,
    NpsSurvey,
    DynamicRosetteButton,
  },
  mixins: [RosetteInfo],
  data() {
    return {
      selectedRosette: {},
      showRosetteModal: false,
      isNpsRosette: false,
      hasActiveMarathons: false,
      hasActiveMarathonsTriggered: false,
      hasNpsSurvey: false,
      npsSurveyId: 0,
    };
  },
  methods: {
    redirectToRosetteDetail() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.RosettesDetail.path}`,
      );
    },
    selectCurrentRosette(rosette) {
      this.selectedRosette = rosette;
      this.showRosetteModal = true;
      this.isNpsRosette = false;
    },
    openNpsSurveyPopup() {
      this.isNpsRosette = !this.isNpsRosette;
      this.showRosetteModal = false;
    },
    async closeRosetteModal() {
      this.isNpsRosette = false;
      await this.getNpsRosetteDetail();
      await this.getRosettesList();
      await this.setProgressValue();
    },
    async activeMarathons() {
      if (!this.hasActiveMarathonsTriggered) {
        const result = await GameApi.checkActiveMarathons();
        result?.data?.Data?.marathon?.forEach(f => {
          if (f.marathonName == 'Haftanın Bulmacası') {
            new StorageHelper({ id: 'PlayableInfo' }).set(f);
            this.hasActiveMarathons = true;
          }
        });
        this.hasActiveMarathonsTriggered = true;
      }
    },
    getNpsRosetteDetail() {
      Common.getNpsRosetteDetail().then(res => {
        if (res?.data?.Data) {
          this.npsSurveyId = res?.data?.Data.surveyId;
          this.hasNpsSurvey = !!res.data.Data.surveyId && res.data.Data.surveyId != 0;
        }
      });
    },
  },
  async updated() {
    await this.setProgressValue();
  },
  async mounted() {
    await this.setProgressValue();
    await this.activeMarathons();
    await this.getRosettesList();
    setTimeout(() => {
      this.checkNpsRosette(this.getNpsRosetteDetail);
    }, 100);
  },
};
</script>

<style scoped lang="scss">
.rosette-container {
  display: flex;
  flex-flow: column;
  width: 685px !important;
  max-width: 690px !important;
}
.rosette-item {
  display: flex;
  flex-flow: column;
  width: 120px;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;
}
.rosette-box {
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;
  text-align: center;
}
.circular-progress {
  display: flex;
  padding: 40px 0;
  border-radius: 8px;
  background: #fff;
  flex-direction: column;
  align-items: center;
}
.progress-bar {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar::before {
  content: '';
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fff;
}
.all-show-button {
  width: 25% !important;
  align-self: center;
  margin: 0 0 40px;
}
.rosette-image {
  position: absolute;
  height: 45px;
  width: 45px;
}
.dynamic-rosette-button {
  width: 120px;
}
.rosette-name {
  height: 48px;
  font-weight: 500;
  color: #23303d;
  text-align: center;
}
</style>
