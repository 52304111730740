<template>
  <div id="divProfileWrapper">
    <transition name="fade">
      <div v-show="isLoaded">
        <div class="profile-header-container">
          <VueWrapperNarrow>
            <div class="profile-header-inner">
              <div class="profile-header-left">
                <div class="profile-picture-holder">
                  <VueImageEditor
                    v-if="photoEditMode"
                    :source.sync="formModel.photo"
                    :cropperOptions="{
                      viewMode: 0,
                      autoCrop: true,
                      dragMode: 'move',
                      background: false,
                      zoomOnTouch: false,
                      zoomOnWheel: false,
                      cropBoxResizable: true,
                      aspectRatio: 1 / 1,
                    }"
                  />
                  <img alt="profile-pic" :src="profilePicture" id="previewImg" />
                </div>
                <span
                  v-if="photoEditMode"
                  class="photo-edit"
                  :class="{ 'photo-edit-empty ': !croppedImage || !croppedImage.length }"
                >
                  <VueIcon
                    :iconName="constants.icons.camera.name"
                    :width="constants.icons.camera.width"
                    :height="constants.icons.camera.height"
                    iconColor="#ffffff"
                  ></VueIcon>
                  <VueText sizeLevel="2" color="white-100">Değiştir</VueText>
                </span>
                <div class="customer-profile-info">
                  <VueText class="customer-name">
                    {{
                      (this.formModel.name ? this.formModel.name : this.nameBeforeChange) +
                        ' ' +
                        (this.formModel.surname ? this.formModel.surname : this.surnameBeforeChange)
                    }}
                  </VueText>
                  <div class="customer-code-container">
                    <VueText class="customer-code-key">Müşteri Kodunuz:</VueText>
                    <VueText class="customer-code-value">{{ formModel.customerCode }}</VueText>
                  </div>
                </div>
              </div>
              <div class="profile-header-right">
                <span @click="editProfile()" class="edit-button">{{
                  photoEditMode ? 'Vazgeç' : 'Profil Düzenle'
                }}</span>
              </div>
            </div>
          </VueWrapperNarrow>
        </div>

        <QRCode />

        <VueWrapperNarrow v-if="!getUserModel.isImageRecognitionUser">
          <Rosettes />
        </VueWrapperNarrow>

        <VueWrapperNarrow>
          <div style="display:flex">
            <VueText class="profile-process-bar"
              >Profil Doluluğu %{{ Math.round(profileRate.toFixed(1)) }}</VueText
            >
            <div class="tooltip">
              <VueIcon
                :iconName="constants.icons.info.name"
                :width="constants.icons.info.width"
                :height="constants.icons.info.height"
                :iconColor="'#79838e'"
                style="marginLeft:5px"
              />
              <div class="top">
                <h3>Eksik alanlar!</h3>
                <ul v-for="(key, i) in nullKeys" :key="i">
                  <li>{{ convertToKeys(key) }}</li>
                </ul>
                <i></i>
              </div>
            </div>
          </div>
          <div class="meter">
            <span :style="{ width: `${profileRate}%` }" />
          </div>

          <VueText sizeLevel="10" weightLevel="3" class="profile-title">Profil Bilgileri</VueText>
          <VueForm ref="form" class="form-wrapper">
            <VueInput
              name="name"
              id="firstname"
              type="text"
              placeholder="Adınız"
              v-model="formModel.name"
              :messageOnTop="false"
              :readonly="!photoEditMode"
              :disabled="!photoEditMode"
              validationRules="required|alpha_spaces"
              validatorName="Kullanıcı Adı"
              class="input-item"
            ></VueInput>
            <VueInput
              name="surname"
              id="lastname"
              type="text"
              placeholder="Soyadınız"
              v-model="formModel.surname"
              :messageOnTop="false"
              :readonly="!photoEditMode"
              :disabled="!photoEditMode"
              validationRules="required|alpha_spaces"
              validatorName="Kullanıcı Soyadı"
              class="input-item"
            ></VueInput>
            <VueInput
              name="phoneNumber"
              id="phoneNumber"
              type="text"
              placeholder="Telefon No"
              v-model="phoneNumber"
              :messageOnTop="false"
              :readonly="true"
              :disabled="true"
              validationRules="numeric|max:10|min:10"
              validatorName="Telefon Numarası"
              class="input-item"
            ></VueInput>
            <transition name="fade">
              <div v-if="formModel.kvkk">
                <VueInput
                  name="email"
                  id="email"
                  type="text"
                  placeholder="E-Posta"
                  v-model="formModel.email"
                  :messageOnTop="false"
                  :readonly="!photoEditMode"
                  :disabled="!photoEditMode"
                  validationRules="email"
                  validatorName="Kullanıcı E-Posta"
                  class="input-item"
                />
                <VueInput
                  name="gender"
                  id="gender"
                  type="text"
                  placeholder="Cinsiyetiniz"
                  v-model="selectedGender"
                  :messageOnTop="false"
                  :readonly="true"
                  :disabled="!photoEditMode"
                  validationRules="alpha_spaces"
                  validatorName="Cinsiyet"
                  @click="selectGender()"
                  class="input-item gender-wrapper"
                >
                  <div
                    class="gender-modal"
                    ref="genderModal"
                    v-if="showSelectGenderModal && photoEditMode"
                  >
                    <div :key="selectedGenderIndex">
                      <VueListItem
                        @click="onRowSelected(item.text, index, item.id)"
                        :text="item.text"
                        v-for="(item, index) in selectGenderModalConfig.modalBody"
                        :key="item.id"
                        :icon="getIcon(item.icon, index)"
                        :contentAlignment="constants.flexAlignment.between"
                      />
                    </div>
                  </div>
                  <VueIcon
                    @click="selectGender()"
                    class="picker-toggle"
                    :iconName="constants.icons.chevronDown.name"
                    :width="constants.icons.chevronDown.width"
                    :height="constants.icons.chevronDown.height"
                  />
                </VueInput>
                <!-- <VueInput
                  v-model="selectedMaritalStatus"
                  @click="selectMaritalStatus()"
                  :messageOnTop="false"
                  :readonly="true"
                  :disabled="!photoEditMode"
                  placeholder="Medeni Durumu"
                  validationRules="alpha_spaces"
                  validatorName="Medeni Durumu"
                  class="input-item gender-wrapper"
                  name="maritalStatus"
                  id="marital"
                  type="text"
                >
                  <div
                    v-if="showSelectMaritalStatusModal && photoEditMode"
                    class="gender-modal"
                    ref="maritalStatus"
                  >
                    <div>
                      <VueListItem
                        v-for="(item, index) in selectMaritalStatusModalConfig.modalBody"
                        @click="onRowMaritalStatusSelected(item.text, index, item.id)"
                        :contentAlignment="constants.flexAlignment.between"
                        :icon="getIconMaritalStatus(item.icon, index)"
                        :text="item.text"
                        :key="item.id"
                      />
                    </div>
                  </div>
                  <VueIcon
                    @click="selectMaritalStatus()"
                    :iconName="constants.icons.chevronDown.name"
                    :width="constants.icons.chevronDown.width"
                    :height="constants.icons.chevronDown.height"
                    class="picker-toggle"
                  />
                </VueInput> -->
                <VueInput
                  name="cityName"
                  id="hometown"
                  type="text"
                  placeholder="Memleket"
                  v-model="selectedCity"
                  :messageOnTop="false"
                  :readonly="true"
                  :disabled="!photoEditMode"
                  validationRules="alpha"
                  validatorName="Memleket"
                  class="input-item city-wrapper"
                  @click="selectCity()"
                >
                  <div
                    class="city-modal"
                    ref="cityModal"
                    v-if="showSelectCityModal && photoEditMode"
                  >
                    <div :key="selectedCityIndex">
                      <VueListItem
                        @click="onRowSelectedCity(item.text, index, item.id)"
                        :text="item.text"
                        v-for="(item, index) in cities"
                        :key="index"
                        :icon="getIconCity(item.icon, index)"
                        :contentAlignment="constants.flexAlignment.between"
                      ></VueListItem>
                    </div>
                  </div>
                  <VueIcon
                    @click="selectCity()"
                    class="picker-toggle"
                    :iconName="constants.icons.chevronDown.name"
                    :width="constants.icons.chevronDown.width"
                    :height="constants.icons.chevronDown.height"
                  ></VueIcon>
                </VueInput>
                <BrandDatePicker
                  class="input-item birthdate-picker-wrapper"
                  ref="birthDatePicker"
                  :startYear="dateOptions.year"
                  :startMonth="dateOptions.month"
                  format="DD.MM.YYYY"
                  v-model="formModel.birthDate"
                  :isDateDisabled="birthDateValidator"
                >
                  <div
                    class="picker-slot-container"
                    :class="[!photoEditMode ? 'picker-disabled' : '']"
                    @click="toggleDatePicker"
                  >
                    <div class="picker-content" :class="[!photoEditMode ? 'content-disabled' : '']">
                      <label>Doğum Tarihi</label>
                      <label>{{ formModel.birthDate }}</label>
                    </div>
                    <div class="picker-toggle">
                      <VueIcon
                        class="picker-chevron"
                        :class="[!photoEditMode ? 'chevron-disabled' : '']"
                        :iconName="constants.icons.chevronDown.name"
                        :width="constants.icons.chevronDown.width"
                        :height="constants.icons.chevronDown.height"
                      ></VueIcon>
                    </div>
                  </div>
                  <span v-if="birthDateMessageShow" class="birth-date-message"
                    >Doğum Tarihi alanı boş bırakılamaz</span
                  >
                </BrandDatePicker>
              </div>
            </transition>
            <div :class="[!photoEditMode ? 'kvkk-disabled' : 'kvkk-button-container']">
              <VueText :color="!photoEditMode ? 'grey-30' : 'grey-40'" sizeLevel="5" weightLevel="1"
                >Kişisel verilerimin uygun olarak işlenmesine izin veriyorum.</VueText
              >
              <BrandButton
                :disabled="!photoEditMode"
                @click="toggleKVKK()"
                iconColor="#ffffff"
                shape="rounded"
                :radius="50"
                class="kvkk-out-button"
                >{{ formModel.kvkk ? 'Geri Al' : 'İzin Ver' }}</BrandButton
              >
            </div>
            <div class="user-privacy">
              <VueText sizeLevel="4" weightLevel="2" color="grey-50"
                ><u @click="showPrivacyNotif()">Gizlilik bildirimini</u>
                okumak için tıklayınız
              </VueText>
            </div>
            <div class="user-contact-pref" :class="[!photoEditMode ? 'text-disabled' : '']">
              <div><VueText sizeLevel="7" weightLevel="2">İleti Yönetimi</VueText></div>
              <div class="user-contact-pref-check">
                <div class="user-contact-pref-check-sms">
                  <VueText sizeLevel="6" weightLevel="2">SMS</VueText>
                  <VueCheck
                    :disabled="!photoEditMode"
                    id="chkSms"
                    v-model="formModel.autoSmsPermission"
                  ></VueCheck>
                </div>
                <div class="user-contact-pref-check-tel">
                  <VueText sizeLevel="6" weightLevel="2">Telefon</VueText>
                  <VueCheck
                    :disabled="!photoEditMode"
                    id="chkCall"
                    v-model="formModel.autoCallPermission"
                  ></VueCheck>
                </div>
              </div>
            </div>
            <div class="user-contract">
              <div class="user-contract-check">
                <VueCheck
                  id="chkContract"
                  :disabled="!photoEditMode"
                  v-if="isContractEnabled"
                  v-model="formModel.readContract"
                ></VueCheck>
                <VueText sizeLevel="4" weightLevel="2" color="grey-50"
                  ><u @click="showContract()">Sözleşmeyi</u>
                  {{ isContractEnabled ? 'okudum ve onaylıyorum' : 'okumak için tıklayınız' }}
                </VueText>
              </div>
              <div v-if="validateForm && !formModel.readContract" class="user-contract-validation">
                <VueText sizeLevel="4" weightLevel="2" color="red-30"
                  >Lütfen sözleşmeyi onaylayın.</VueText
                >
              </div>
            </div>
          </VueForm>
        </VueWrapperNarrow>
        <VueWrapperNarrow>
          <div class="update-btn" v-if="photoEditMode">
            <BrandButton
              :size="sizes.xxLarge"
              :contentAlignment="constants.flexAlignment.center"
              type="submit"
              :disabled="!isProfileChanged"
              @click="handleSubmit"
              ><VueText sizeLevel="8" weightLevel="3">GÜNCELLE</VueText>
            </BrandButton>
          </div>
        </VueWrapperNarrow>
        <VueWrapperNarrow>
          <div class="profile-bottom-container">
            <VueText color="grey-50" sizeLevel="10" weightLevel="3" class="company-info-header"
              >Firma Bilgileri</VueText
            >
            <div class="company-info">
              <VueText color="grey-30" sizeLevel="5">Firma Adı:</VueText>
              <VueText color="grey-40" sizeLevel="8" weightLevel="3">{{
                formModel.customerName
              }}</VueText>
            </div>
            <div class="company-address">
              <VueText color="grey-30" sizeLevel="5">Adres:</VueText>
              <VueText color="grey-40" sizeLevel="8" weightLevel="3">{{
                formModel.customerAddress
              }}</VueText>
            </div>
          </div>
        </VueWrapperNarrow>
        <VueWrapperNarrow class="weekly-wrapper">
          <VueText class="info-text" :color="'grey-30'" weightLevel="1"
            >Ürün ön siparişlerinizi verebileceğiniz günleri ve rut düzeninize ait detayları
            aşağıdaki Ziyaret Günlerim/Ön-Sipariş Günlerim başlıklarına tıklayarak
            görebilirsiniz</VueText
          >
          <div class="tabs">
            <div
              :class="activeTab == 'visitDays' ? 'active-title' : ''"
              @click="setActiveTab('visitDays')"
            >
              <VueText
                :color="activeTab === 'visitDays' ? 'currentColor' : 'grey-30'"
                sizeLevel="5"
                weightLevel="3"
                >ZİYARET GÜNLERİM</VueText
              >
            </div>
            <div
              :class="activeTab == 'orderDays' ? 'active-title' : ''"
              @click="setActiveTab('orderDays')"
            >
              <VueText
                :color="activeTab === 'orderDays' ? 'currentColor' : 'grey-30'"
                sizeLevel="5"
                weightLevel="3"
                >ÖN SİPARİŞ GÜNLERİM</VueText
              >
            </div>
          </div>
          <hr class="bottom" />

          <div class="days-table">
            <div class="day" v-for="(day, index) in data.days" :key="index">
              <div class="day-name">{{ day }}</div>
              <div class="day-check" v-for="(partner, i) in partners" :key="i">
                <img
                  class="partner-logo"
                  v-if="index === 0"
                  :src="partner.partnerImage"
                  :alt="partner.partnerName"
                />
                <VueIcon
                  v-if="isChecked(day, partner)"
                  :iconName="constants.icons.iconChecked.name"
                  :width="constants.icons.iconChecked.width"
                  :height="constants.icons.iconChecked.height"
                />
              </div>
            </div>
          </div>
        </VueWrapperNarrow>
      </div>
    </transition>
    <BrandInfoPopup :show.sync="processTypeModal">
      <div class="process-info-modal" v-if="contractContent">
        <h2>Kullanıcı Sözleşmesi</h2>
        <div class="static-desc" v-html="contractContent"></div>
      </div>
    </BrandInfoPopup>
  </div>
</template>

<script>
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandDatePicker from '@/components/brand/BrandDatePicker/BrandDatePicker.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { Account, Common, User } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import StorageProps from '@/mixins/storageProps.js';
import VueCheck from '@/components/shared/VueCheck/VueCheck.vue';
import StaticContent from '@/services/Api/staticContents';
import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';
import VueImageEditor from '@/components/VueImageEditor/VueImageEditor.vue';
import dateUtils from '@/mixins/dateUtils';
import Rosettes from '@/views/pages/secure/settings/Rosettes.vue';
import { mapGetters } from 'vuex';
import QRCode from '@/views/pages/secure/settings/QRCode.vue';

export default {
  name: 'Profile',
  mixins: [StorageProps, dateUtils],
  components: {
    VueInput,
    VueText,
    VueForm,
    BrandButton,
    VueIcon,
    VueListItem,
    VueCheck,
    VueWrapperNarrow,
    BrandInfoPopup,
    VueImageEditor,
    BrandDatePicker,
    Rosettes,
    QRCode,
  },
  data() {
    return {
      isLoaded: false,
      nullKeys: [],
      processTypeModal: false,
      contractContent: '',
      isContractEnabled: false,
      validateForm: false,
      birthDateMessageShow: false,
      cities: [],
      file: '',
      renderKey: 99,
      isProfileChanged: false,
      initialFormValues: {
        city: {
          cityId: 1,
          cityName: 'Adana',
        },
      },
      phoneNumber: '',
      formModelCurrent: null,
      formModel: {
        id: '',
        birthDate: null,
        city: {
          cityId: -1,
          cityName: '',
        },
        customerAddress: '',
        customerCode: '',
        customerName: '',
        email: '',
        gender: '',
        kvkk: false,
        name: '',
        phoneNumber: '',
        photo: '',
        readContract: false,
        autoCallPermission: false,
        autoSmsPermission: false,
        surname: '',
        //  maritalStatus: '',
      },
      nameBeforeChange: '',
      surnameBeforeChange: '',
      croppedImage: '',
      submitted: false,
      photoEditMode: false,
      showSelectGenderModal: false,
      // showSelectMaritalStatusModal: false,
      selectedGender: null,
      selectedGenderIndex: '',
      // selectedMaritalStatus: null,
      // selectedMaritalStatusIndex: '',
      selectedCity: null,
      selectedCityIndex: '',
      showSelectCityModal: false,
      selectGenderModalConfig: {
        title: 'Cinsiyet',
        modalBody: [],
      },
      // selectMaritalStatusModalConfig: {
      //   title: 'Medeni Durumu',
      //   modalBody: [
      //     {
      //       id: 1,
      //       text: 'Bekar',
      //       icon: ICON_VARIABLES.circle,
      //     },
      //     {
      //       id: 2,
      //       text: 'Evli ve Çocuklu',
      //       icon: ICON_VARIABLES.circle,
      //     },
      //     {
      //       id: 3,
      //       text: 'Evli ve Çocuksuz',
      //       icon: ICON_VARIABLES.circle,
      //     },
      //   ],
      // },
      activeTab: 'visitDays',
      data: {
        days: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
      },
      partners: [],
      profileRate: 0,
    };
  },
  watch: {
    formModel: {
      deep: true,
      handler: function() {
        this.isProfileChanged = true;
      },
    },
  },
  beforeCreate() {
    Common.getGenders().then(res => {
      if (res.data && res.data.Data && res.data.Data.genders) {
        let genders = res.data.Data.genders;
        genders.map(gender => {
          this.selectGenderModalConfig.modalBody.push({
            id: gender.Key,
            text: gender.Value,
            icon: ICON_VARIABLES.circle,
          });
        });
      }
    });

    Common.getCities()
      .then(res => {
        let cities = res.data.Data.cities;
        if (res && cities) {
          cities.map((city, index) => {
            this.cities.push({
              id: index + 1,
              text: city.cityName,
              icon: ICON_VARIABLES.circle,
            });
          });
        }
      })
      .then(() => this.renderKey++);
  },
  created() {
    this.getProfileData();
    this.getRouteDays();
    window.addEventListener('click', e => {
      if (this.$refs.genderModal) {
        if (
          this.showSelectGenderModal &&
          e.target.id !== 'gender' &&
          !this.$refs.genderModal.contains(e.target) &&
          e.target.tagName !== 'svg' &&
          e.target.tagName !== 'path'
        ) {
          this.showSelectGenderModal = false;
        }
      }
      // if (this.$refs.maritalStatus) {
      //   if (
      //     this.showSelectMaritalStatusModal &&
      //     e.target.id !== 'marital' &&
      //     !this.$refs.maritalStatus.contains(e.target) &&
      //     e.target.tagName !== 'svg' &&
      //     e.target.tagName !== 'path'
      //   ) {
      //     this.showSelectGenderModal = false;
      //   }
      // }
      if (this.$refs.cityModal) {
        if (
          this.showSelectCityModal &&
          e.target.id !== 'hometown' &&
          !this.$refs.cityModal.contains(e.target) &&
          e.target.tagName !== 'svg' &&
          e.target.tagName !== 'path'
        ) {
          this.showSelectCityModal = false;
        }
      }
    });
  },

  computed: {
    ...mapGetters('auth', ['getUserModel']),
    dateOptions() {
      let currentDate = new Date();
      let validDate = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      return { month: validDate.getMonth(), year: validDate.getFullYear() };
    },
    profilePicture() {
      const currUserPic = this.currentUser ? this.currentUser.picture : null;

      const photo =
        this.photoEditMode && this.croppedImage
          ? this.croppedImage
          : this.formModel.photo || currUserPic || process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;

      return photo || process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  methods: {
    convertToKeys(key) {
      switch (key) {
        case 'Name':
          return 'Ad';
        case 'surname':
          return 'Soyad';
        case 'phoneNumber':
          return 'Telefon No';
        case 'email':
          return 'E-Posta';
        case 'gender':
          return 'Cinsiyet';
        // case 'maritalStatus':
        //   return 'Medeni Durumu';
        case 'birthDate':
          return 'Doğum Tarihi';
        case 'kvkk':
          return 'KVKK';
        case 'cityName':
          return 'Memleket';
        case 'Photo':
          return 'Profile Fotoğrafı';
      }
      return key;
    },

    getRouteDays() {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            this.partners = res.data.Data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    birthDateValidator: date => {
      let currentDate = new Date();
      let validDate = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      return date > validDate;
    },
    toggleDatePicker() {
      if (!this.photoEditMode) return;
      this.$refs.birthDatePicker.$children[0].toggle();
    },
    toggleKVKK() {
      this.formModel.kvkk = !this.formModel.kvkk;
    },
    showPrivacyNotif() {
      window.open(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.DataPolicy.path}`,
      );
    },
    showContract() {
      StaticContent.getStaticContent('TPSozlesmesi').then(res => {
        let {
          Data: { content },
        } = res.data;

        this.contractContent = content;
        this.processTypeModal = true;
      });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('profilePhoto', this.file);
      Account.uploadProfilePhoto(formData).then(res => {
        if (res.data && res.data.Data && res.data.Data.success) {
          const user = new StorageHelper({
            id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY,
          }).get();

          user.picture = res.data.Data.url;
          new StorageHelper({
            id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY,
          }).set(user);
          this.$store.dispatch('auth/setUserModel', user);
        }
      });
    },
    editProfile() {
      this.showSelectCityModal = false;
      this.showSelectGenderModal = false;
      //this.showSelectMaritalStatusModal = false;

      this.photoEditMode = !this.photoEditMode;

      if (!this.photoEditMode) {
        this.formModel = { ...this.formModelCurrent };
      }
      if (this.birthDateMessageShow) {
        this.birthDateMessageShow = false;
      }
    },
    getIcon(itemIcon, index) {
      return index === this.selectedGenderIndex ? ICON_VARIABLES['checked'] : itemIcon;
    },
    // getIconMaritalStatus(itemIcon, index) {
    //   return index === this.selectMaritalStatus ? ICON_VARIABLES['checked'] : itemIcon;
    // },
    getIconCity(itemIcon, index) {
      return index === this.selectedCityIndex ? ICON_VARIABLES['checked'] : itemIcon;
    },
    getProfileData() {
      if (this.currentUser.id) {
        let userId = this.currentUser.id;

        Account.getProfileByUserId(userId)
          .then(res => {
            const user = res.data.Data.userProfile;
            let userInfo = {
              ...user,
              birthDate: user.birthDate ? this.parseISODate(user.birthDate) : null,
            };

            this.phoneNumber = userInfo.phoneNumber;

            const currentUser = new StorageHelper({
              id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY,
            }).get();

            const modifiedUser = {
              ...currentUser,
              name: userInfo.name,
              surname: userInfo.surname,
              picture: userInfo.photo,
            };

            this.nameBeforeChange = userInfo.name;
            this.surnameBeforeChange = userInfo.surname;

            new StorageHelper({
              id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY,
            }).set(modifiedUser);
            this.$store.dispatch('auth/setUserModel', modifiedUser);

            if (!user.city) {
              userInfo['city'] = { ...this.initialFormValues.city };
            }

            this.formModel = { ...userInfo };
            this.formModelCurrent = { ...userInfo };

            var profileRateData = {
              birthDate: userInfo.birthDate,
              cityName: userInfo.city.cityName,
              email: userInfo.email,
              gender: userInfo.gender,
              kvkk: userInfo.kvkk,
              // maritalStatus: userInfo.maritalStatus,
              name: userInfo.name,
              phoneNumber: userInfo.phoneNumber,
              photo: userInfo.photo,
              surname: userInfo.surname,
            };

            const profileRateDataValues = Object.values(profileRateData);
            const notNulls = [];

            profileRateDataValues.forEach(f => {
              if (f != null && f != '' && f.toString() != '0') {
                notNulls.push(f);
              }
            });
            Object.entries(profileRateData).forEach(([key, value]) => {
              if (value == null || value == '' || value.toString() == '0') {
                this.nullKeys.push(key);
              }
            });

            this.profileRate = (notNulls.length / profileRateDataValues.length) * 100;

            if (this.formModel.gender) {
              this.setSelectedGender(this.formModel.gender);
            }
            // if (this.formModel.maritalStatus) {
            //   this.setSelectedMaritalStatus(this.formModel.maritalStatus);
            // }

            if (this.formModel.city) {
              this.setSelectedCity(this.formModel.city.cityId);
            }
            if (!this.formModel.readContract) {
              this.isContractEnabled = true;
            }
          })
          .finally(() => {
            this.isLoaded = true;
          });
      }
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.validateForm = true;
      const isValid = await this.$refs.form.$refs.observer.validate();
      let birthDateValid = this.formModel.birthDate ? true : false;
      if (!isValid || !this.formModel.readContract || !birthDateValid) {
        this.birthDateMessageShow = !birthDateValid;
        return;
      }
      this.validateForm = false;
      this.submitted = true;
      let submittedForm = {
        userProfile: {
          ...this.formModel,
          birthDate: this.formModel.birthDate
            ? this.toISODateString(this.formModel.birthDate)
            : null,
        },
      };
      delete submittedForm.userProfile.phoneNumber;
      Account.updateProfile(submittedForm.userProfile).then(res => {
        if (res.data?.Data?.success) {
          if (res.data?.Data?.data?.agreementErrorMessage) {
            const errorModalConfig = {
              isModalOpened: true,
              modalText: `${res.data?.Data?.data?.agreementErrorMessage}`,
              modalType: 'error',
              alertType: 'error',
              firstButtonText: 'KAPAT',
            };
            this.$store.dispatch('app/setModalConfig', errorModalConfig);
          }
          this.getProfileData();
          this.isContractEnabled = !submittedForm.userProfile.readContract;
          this.photoEditMode = false;
        }
      });
    },
    selectGender() {
      this.showSelectGenderModal = !this.showSelectGenderModal;
    },
    // selectMaritalStatus() {
    //   this.showSelectMaritalStatusModal = !this.showSelectMaritalStatusModal;
    // },
    selectCity() {
      this.showSelectCityModal = !this.showSelectCityModal;
    },
    getSelectedGender() {
      this.showSelectGenderModal = false;
      document.documentElement.style.overflow = 'auto';
    },
    setSelectedGender(id) {
      let genderIndex = this.selectGenderModalConfig.modalBody.findIndex(item => item.id == id);
      if (genderIndex != -1) {
        this.selectedGender = this.selectGenderModalConfig.modalBody[genderIndex].text;

        this.selectedGenderIndex = genderIndex;
      }
    },
    // setSelectedMaritalStatus(id) {
    //   let statusIndex = this.selectMaritalStatusModalConfig.modalBody.findIndex(
    //     item => item.id == id,
    //   );
    //   if (statusIndex != -1) {
    //     this.selectedMaritalStatus = this.selectMaritalStatusModalConfig.modalBody[
    //       statusIndex
    //     ].text;

    //     this.selectedMaritalStatusIndex = statusIndex;
    //   }
    // },
    onRowSelected(event, index, id) {
      if (index === this.selectedGenderIndex) return;

      this.selectedGender = event;
      this.formModel.gender = id.toString();
      this.selectedGenderIndex = index;
      this.showSelectGenderModal = false;
    },
    // onRowMaritalStatusSelected(event, index, id) {
    //   if (index === this.selectedMaritalStatusIndex) return;

    //   this.selectedMaritalStatus = event;
    //   this.formModel.maritalStatus = id.toString();
    //   this.selectedMaritalStatusIndex = index;
    //   this.showSelectMaritalStatusModal = false;
    // },
    setSelectedCity(id) {
      let cityIndex = this.cities.findIndex(item => item.id === id);
      if (cityIndex != -1) {
        this.selectedCity = this.cities[cityIndex].text;

        this.selectedCityIndex = cityIndex;
      }
    },
    onRowSelectedCity(event, index, id) {
      if (index === this.selectedCityIndex) return;

      this.selectedCity = event;
      this.formModel.city.cityId = id;
      this.formModel.city.cityName = event;
      this.selectedCityIndex = index;
      this.selectedCityIndex = index;
      this.showSelectCityModal = false;
    },
    setActiveTab(tab) {
      if (this.activeTab != tab) {
        this.activeTab = tab;
      }
    },
    isChecked(day, partner) {
      if (this.activeTab === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/_animations.scss';
@function asset($path) {
  @return url('~@/assets/#{$path}');
}

.profile-picture-holder {
  height: palette-space-level(70);
  width: palette-space-level(70);
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  pointer-events: none;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    max-height: 100%;
  }
}

// =======================

.wrapper-narrow {
  position: relative;
}

input:disabled {
  color: palette-color-level('grey', '50');
}
.photo-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, rgba(180, 194, 211, 0.6), #23303d);
}

.photo-preview,
.photo-edit {
  position: absolute;
  border-radius: palette-radius-level('0');
  width: palette-space-level(70);
  height: palette-space-level(70);
  left: 0;
  top: 0;
  cursor: pointer;
}
.user-privacy {
  margin-bottom: palette-space-level(20);
  margin-top: palette-space-level(20);
}
.user-contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: palette-space-level(20);
  margin-top: palette-space-level(20);
  &-check {
    display: flex;
    flex-direction: row;
  }
  &-validation {
    margin-top: palette-space-level(5);
  }
}
.user-contact-pref {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid palette-color-level('grey', 40);
  border-radius: palette-radius-level(2);
  padding: palette-space-level(12) palette-space-level(10) palette-space-level(12)
    palette-space-level(20);
  &.text-disabled {
    color: palette-color-level('grey', 30);
  }

  &-check {
    div {
      &:first-child {
        padding-bottom: palette-space-level(10);
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        text-align: right;
        padding-right: palette-space-level(10);
      }
    }
  }
}
.kvkk-button-container {
  border: 1px solid palette-color-level('grey', '40');
}
.kvkk-button-container,
.kvkk-disabled {
  border: 1px solid palette-color-level('grey', '40');
  display: flex;
  padding: palette-space-level(20);
  border-radius: palette-radius-level('2');
  align-items: center;
  margin-bottom: palette-space-level(20);
  justify-content: space-between;
  width: 100%;
  button.kvkk-out-button {
    //TODO: BRANDBUTTON DÜZELTMELERİNDEN SONRA DEĞİŞECEK
    width: unset !important;
    padding: palette-space-level('5') palette-space-level('15') !important;
  }
}
.profile-header-container {
  padding: palette-space-level('10');
  background-color: palette-color-level('grey', '10');
  margin-bottom: palette-space-level('20');
  margin-top: palette-space-level('20');
}

.profile-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-header-left,
.profile-header-right {
  display: flex;
  align-items: center;
}

.profile-header-left,
.customer-code-container {
  position: relative;
}

.customer-profile-info {
  padding-left: palette-space-level('20');
  position: relative;

  .customer-code-container {
    white-space: nowrap;
  }

  .customer-name {
    font-weight: palette-font-weight-level(4);
    font-size: palette-font-size-level('7');
    margin-bottom: palette-space-level('5');
  }

  .customer-code-key {
    display: inline-block;
    font-size: palette-font-size-level('5');
    color: palette-color-level('grey', '30');
  }

  .customer-code-value {
    display: inline-block;
    font-size: palette-font-size-level('4');
    color: palette-color-level('grey', '40');
    padding-left: palette-space-level('5');
  }
}

.profile-process-bar {
  display: inline-block;
  font-size: palette-font-size-level('5');
  color: palette-color-level('grey', '30');
}

.profile-header-right {
  .edit-button {
    border: 1px solid black;
    width: 150px;
    text-align: center;
    padding: palette-space-level('10');
    font-size: palette-font-size-level('3');
    border-radius: palette-radius-level(2);
    font-weight: palette-font-weight-level(4);
    font-size: palette-font-size-level('5');
    color: palette-color-level('grey', '40');
    cursor: pointer;
  }
}

.profile-bottom-container {
  margin-top: palette-space-level('60');
  .company-info,
  .company-address {
    border-top: 1px solid palette-color-level('grey', '20');
    background-color: palette-color-level('grey', '10');
    padding: palette-space-level(20);
    &:nth-child(3) {
      border-bottom: 1px solid palette-color-level('grey', '20');
    }
    p {
      margin-bottom: palette-space-level('5');
    }
  }

  .company-info-header {
    margin-bottom: palette-space-level('20');
  }
}

.static-desc {
  max-height: palette-space-level(400);
  overflow: auto;
  padding: palette-space-level(20);
}

.form-wrapper {
  .gender-wrapper,
  .city-wrapper {
    cursor: pointer;
  }
  .input-item {
    border: 1px solid #23303d;
    border-radius: palette-radius-level(2);
    margin-bottom: palette-space-level('20');
    /deep/ input {
      border: none;
      margin-top: palette-space-level('10');
    }
    /deep/ label {
      top: 20px;
    }
    /deep/ #gender {
      cursor: pointer;
    }

    /deep/ #hometown {
      cursor: pointer;
    }

    /deep/ [class^='VueInput_message'] {
      padding-left: 10px;
    }
    .birth-date-message {
      display: block;
      height: $input-message-height;
      padding: $input-message-padding;
      position: relative;
      color: $input-error-color;
      font-size: palette-font-size-level(3);
      font-weight: $input-message-font-weight;
      padding-left: palette-space-level(10);
    }
  }
  .gender-wrapper,
  .city-wrapper {
    position: relative;
    .picker-toggle {
      position: absolute;
      right: palette-space-level(20);
      top: palette-space-level(35);
      cursor: pointer;
    }
  }
}
.profile-title {
  margin-top: palette-space-level('30');
  margin-bottom: palette-space-level('20');
}
.update-btn {
  margin-top: palette-space-level('30');
}

.gender-modal {
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: palette-color-level('white', '100');
  border: solid 1px palette-color-level('grey', '30');
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  margin-top: palette-space-level('20');
  min-width: 663px;
  min-height: 220px;
  left: -1px;
  /deep/ .single-list-item {
    cursor: pointer;
    span {
      margin-left: palette-space-level('40');
    }
  }
}

.city-modal {
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: palette-color-level('white', '100');
  border: solid 1px palette-color-level('grey', '30');
  border-radius: palette-radius-level(2);
  margin-top: palette-space-level('20');
  min-width: 663px;
  max-height: palette-space-level(400);
  overflow-y: auto;
  left: -1px;
  /deep/ .single-list-item {
    cursor: pointer;
    span {
      margin-left: palette-space-level('40');
    }
  }
}

.process-info-modal {
  h2 {
    text-align: center;
    font-size: palette-font-size-level(12);
    font-weight: bold;
    margin-bottom: palette-space-level('20');
  }
}
.birthdate-picker-wrapper {
  min-height: 79px;
  display: flex;
  flex-direction: column;
  .picker-slot-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: palette-space-level(12) palette-space-level(20);
    justify-content: space-between;

    &.picker-disabled {
      opacity: 0.6;
      //   cursor: not-allowed !important;
    }
    .picker-content {
      width: 100%;
      cursor: pointer;
      &.content-disabled {
        cursor: not-allowed !important;
      }
      label {
        display: block;
        cursor: inherit;
        &:first-child {
          padding-bottom: palette-space-level(10);
          font-size: palette-font-size-level(5);
          font-weight: palette-font-weight-level(1);
        }
        &:last-child {
          font-size: palette-font-size-level(8);
          font-weight: palette-font-weight-level(2);
          color: palette-color-level('grey', 40);
        }
      }
    }
    .picker-toggle {
      cursor: default;
      padding-left: palette-space-level(20);
      .picker-chevron {
        cursor: pointer;
        &.chevron-disabled {
          cursor: not-allowed !important;
        }
      }

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.tabs {
  display: flex;
  margin-top: palette-space-level(30);
  z-index: 5;
  & > div {
    padding-bottom: palette-space-level(10);
    margin-right: palette-space-level(40);
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      border-radius: palette-radius('radius-11');
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -3px;
      left: 0;
      background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.active-title {
      position: relative;
      &::after {
        opacity: 1;
      }
    }
  }
}
hr.bottom {
  position: relative;
  top: 1px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
}
.days-table {
  margin-top: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
}
.day {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  &-name {
    background-color: palette-color-level('grey', 10);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.info-text {
  margin-top: palette-space-level(60);
}
.weekly-wrapper {
  margin-bottom: palette-space-level(100);
}
.partner-logo {
  position: absolute;
  left: -100%;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 10px;
}

.meter {
  box-sizing: content-box;
  height: 10px;
  position: relative;
  margin: 10px 0 10px 0;
  background: #d9d9d9;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px #d9d9d9;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #3aac5d;
  position: relative;
  overflow: hidden;
}
.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip h3 {
  margin: 12px 0;
  font-weight: bold;
}

.tooltip .top {
  min-width: 250px;
  max-width: 400px;
  top: -20px;
  left: 50%;
  transform: translate(-30%, -100%);
  padding: 10px 20px;
  background-color: #fff;
  font-weight: normal;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  color: #79838e;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 30%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
</style>
